<template>
	<div>
		<v-card dense outline color="warning">
			<v-card-title>
				<v-row>
					<v-col cols="10">
						<h3>{{ "Mis tareas - " + this.project.PrjName }}</h3>
					</v-col>
					<v-col cols="1">
						<v-btn
							fab
							x-small
							v-if="project != null"
							color="success"
							@click="openDialogTask()"
						>
							<v-icon>{{ 'mdi mdi-plus' }}</v-icon>
						</v-btn>
					</v-col>
					<v-col cols="1">
						<v-btn
							small
							color="success"
							@click="openDialogDashboard()"
						>
							<v-icon>{{'mdi-view-dashboard'}}</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>
			<v-container>
				<v-row>
					<v-col
						v-for="item in stages"
						:key="item.id"
					>
						<v-card
							dense
							outlined
						>
							<v-card-title style="margin-bottom : -25px">
								<h4>{{ item.StrName }}</h4>
							</v-card-title>
							<v-card-title>
								<v-row>
									<v-col>
										<Container
											group-name="trello"
											@drag-start="handleDragStart(item.StrName, $event, item.StrID)"
											@drop="handleDrop(item.StrName.toString(), $event, item.StrID)"
											:get-child-payload="getChildPayload"
										>
											<Draggable 
												v-for="task in item.child"
												:key="task.TskID"
											>
												<v-card
													dense
													outlined
													style="margin-bottom : 2px; padding-top : -10px"
												>
													<v-card-title style="padding-top : -120px">
														{{ task.TskTitle }}
														<v-spacer></v-spacer>
														<v-btn
															x-small
															text
															fab
															color="primary"
															@click="deleteTask(task)"
														>
															<v-icon>{{'fas fa-trash'}}</v-icon>
														</v-btn>
													</v-card-title>
													<v-card-actions style="margin-top : -30px">
														<v-btn
															icon
															@click="show = !show"
														>
															<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
														</v-btn>
													</v-card-actions>
													<v-expand-transition>
														<div v-show="show">
															<v-divider></v-divider>
															<v-card-text>
																<v-timeline
																	align-left
																	dense
																>
																	<v-timeline-item
																		color="primary"
																		small
																	>
																		<div>
																			<div class="font-weight-normal">
																					<strong>{{ task.AreName }}</strong> {{ task.TskDateName }}
																			</div>
																			<div>{{ task.TskDescription }}</div>
																		</div>
																	</v-timeline-item>
																</v-timeline>
															</v-card-text>
														</div>
													</v-expand-transition>
												</v-card>
											</Draggable>
										</Container>
									</v-col>
								</v-row>
							</v-card-title>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<!-- elegir el proyecto -->
		<v-dialog
			v-if="dialogProject"
			v-model="dialogProject"
			persistent
		>
			<project-task
				@selectedProject="selectedProject($event)"
			></project-task>
		</v-dialog>

		<!-- agregar una tarea -->
		<v-dialog
			v-if="dialogTask"
			v-model="dialogTask"
			width="500"
		>
			<add-task
				@closeDialogTask="closeDialogTask()"
				:project="project"
			></add-task>
		</v-dialog>

		<!-- modal para dashboard -->
		<v-dialog
			v-model="dialogDashboard"
			v-if="dialogDashboard"
			fullscreen
		>
			<dashboard-task
				:project="project"
				@closeDialogDashboard="closeDialogDashboard()"
			></dashboard-task>
		</v-dialog>

	</div>
</template>

<script>
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';

	import ProjectTask from './ProjectTask.vue';
	import DashboardTask from './DashboardTask.vue';
	import {Container, Draggable} from "vue-smooth-dnd";
	import AddTask from './AddTask.vue';

	export default {
		components: {
			ProjectTask,
			Container,
			Draggable,
			AddTask,
			DashboardTask
		},

		data() {
			return {
				
				dialogTask : false,
				dialogProject : false,
				project : {
					PrjName : ''
				},
				stages : [],
				dragginCard : {
					lane: '',
					index : -1,
					cardData : {}
				},
				show: false,
				stageMovement : {
					source : '',
					destiny : ''
				},
				dialogDashboard : false,
			}
		},

		methods: {
			openDialogDashboard()
			{
				this.dialogDashboard = true;
			},

			closeDialogDashboard()
			{
				this.dialogDashboard = false;
			},

			openDialogTask()
			{
				this.dialogTask = true;
			},

			closeDialogTask()
			{
				this.dialogTask = false;
				this.getListTask();
			},

			selectedProject(project)
			{
				this.project = project;
				this.dialogProject = false;
				this.getListTask();
			},

			handleDragStart(lane, dragResult, StrID) {
				console.log('StrID', StrID);
				const { payload, isSource } = dragResult;
				if(isSource)
				{
					let cardSelected = this.stages.find(element =>{
						return element.StrName == lane
					});
					
					this.dragginCard = {
						StrID : StrID,
						lane ,
						index : payload.index,
						cardData : {
							...cardSelected.child[payload.index]
						}
					};
				}
			},

			handleDrop(lane, dropResult, StrID)
			{
				const { removedIndex, addedIndex } = dropResult;

				let cardSelectedDestination = this.stages.find(element =>{
					return element.StrName == lane
				});

				if(lane == this.dragginCard.lane && removedIndex == addedIndex)
				{
					return;
				}

				if(removedIndex != null)
				{
					/* this.validateTransfer(cardSelectedDestination); */
					cardSelectedDestination.child.splice(removedIndex, 1);
				}

				if(addedIndex != null)
				{
					this.saveTaskMovement(StrID);
					cardSelectedDestination.child.splice(addedIndex, 0, this.dragginCard.cardData);
				}
				/* this.$fun.alert("Actualizado Correctamente", "success");
				return; */
			},

			saveTaskMovement(destiny)
			{
				let task = this.dragginCard.cardData;
				task.StrID = destiny;
				task.UsrUpdateID = this.$fun.getUserID();
				
				_sTaskService
				.save(task, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Actualizado correctamente", "success");
						return;
					}
				})
			},

			validateTransfer(stage)
			{
				let message = {
					error : 0,
					message : ""
				};

				console.log('estado destino', stage);
			},

			getChildPayload(index)
			{
				return { index, };
			},

			getListTask()
			{
				this.project.UsrID = this.$fun.getUserID();
				this.project.IsAdmin = this.$fun.isAdmin() ? 1 : 0;
				console.log('proyect', this.project);
				_sTaskService
				.taskbystatus(this.project, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.stages = resp.data;
						console.log('hijos ', resp.data);
					}
				})
			},

			deleteTask(task)
			{
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r =>{
					if(r.value)
					{
						task.SecStatus = 0;
						task.UsrUpdateID = this.$fun.getUserID();
						
						_sTaskService
						.save(task, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Actualizado correctamente", "success");
								this.getListTask();
								return;
							}
						})
					}
				})
				
			}
		},

		created () {
			this.dialogProject = true;
		},
	}
</script>

<style scoped>

</style>