<template>
	<v-card>
		<v-row>
			<v-col>
				<s-toolbar
					:label="'Dashboard - Proyecto ' + project.PrjName"
					close
					@close="closeDialogDashboard()"
					color="#E0F7F2"
				></s-toolbar>
			</v-col>
		</v-row>
		<v-card-text>
			<v-row>
				<v-col>
					<v-card dense outlined>
						<v-card-text>
							<v-row>
								<v-col>
									<s-select 
										label="Año" 
										:items="years" 
										outlined 
										hide-details 
										dense
										v-model="filter.year"
									></s-select>
								</v-col>
								<v-col>
									<s-select 
										label="Mes" 
										:items="itemsMonth" 
										outlined 
										hide-details 
										dense
										v-model="filter.month"
									></s-select>
								</v-col>
								<!-- <v-col>
									<s-select
										label="Responsable"
										:items="workerstic"
										autocomplete
										clearable
										item-text="WorkerName"
										item-value="PrsID"
									></s-select>
								</v-col> -->
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-card dense outlined>
						<v-card-title>
							<b>{{"Tickets del mes"}}</b>
						</v-card-title>
						<v-card-text>
							<h1>{{ taskByMonth.TicketsByMonth }}</h1>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col>
					<v-card dense outlined>
						<v-card-title>
							<b>{{"Tickets abiertos"}}</b>
						</v-card-title>
						<v-card-text>
							<h1>{{ taskByMonth.TicketsOpen }}</h1>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-card dense outlined>
						<v-card-title>
							<b>{{"Tickets por responsable"}}</b>
						</v-card-title>
						<v-card-text>
							<dashboard-task-by-responsible-vue></dashboard-task-by-responsible-vue>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col>
					<v-card dense outlined>
						<v-card-title>
							<b>{{"Tickets por área"}}</b>
						</v-card-title>
						<v-card-text>
							<h1>{{"163"}}</h1>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-card dense outlined>
						<v-card-title>
							<b>{{"Tickets de los ultimos 6 meses"}}</b>
						</v-card-title>
						<v-card-text>
							<h1>{{"163"}}</h1>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';
	import DashboardTaskByResponsibleVue from './DashboardTaskByResponsible.vue';

	export default {
		components: {
			DashboardTaskByResponsibleVue,
		},

		props: {
			project: {
				type: Object,
				default(rawsProps) {
					return { message: 'hello' }
				} 
			},
		},

		data() {
			return {
				workerstic: [],
				filter : {
					year : 0,
					month : 0,
				},
				taskByMonth : {},
				taskByResponsible : [],
				taskByArea : [],
				taskByLastMonth : [],
				month : 0,
				year : 0,
			}
		},

		methods: {
			closeDialogDashboard() {
				this.$emit("closeDialogDashboard")
			},

			getTicWorkers()
			{
				let obj = {
					AreID: 32
				}

				_sTaskService
				.ticworkers(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.workerstic = resp.data;
					}
				})
			},

			getDate()
			{
				this.years = this.$fun.getTop5LastYears();
				this.itemsMonth = this.$const.Months;
				this.filter.year = this.$moment().year();
				this.filter.month = (this.$moment().month()) + 1;
			},

			getTaskByMonth()
			{
				_sTaskService
				.ticketbymonth(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.taskByMonth = resp.data;
					}
				})
			},

			getTaskByResponsible()
			{
				_sTaskService
				.ticketbyresponsible(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.taskByResponsible = resp.data;
					}
				})
			},

			getTaskByArea()
			{
				_sTaskService
				.ticketbyarea(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.taskByArea = resp.data;
					}
				})
			},

			getTaskByLastMonth()
			{
				_sTaskService
				.ticketbylastmonth(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.taskByLastMonth = resp.data;
					}
				})
			}

		},

		created () {
			this.getTicWorkers();
			this.getDate();
			this.getTaskByMonth();
			this.getTaskByResponsible();
			this.getTaskByArea();
			this.getTaskByLastMonth();
		},
	}
</script>
