<template>
	<v-card>
		<s-toolbar
			label="Agregar Tarea"
			save
			@save="saveTask()"
			close
			@close="closeDialogTask()"
		></s-toolbar>
		<v-card class="pb-3">
			<v-container>
				<v-row>
					<v-col>
						<v-checkbox
							label="Tarea Programada"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-text
							label="Titulo"
							v-model="task.TskTitle"
							ref="tsktitleref"
						></s-text>
					</v-col>
					<v-col class="s-col-form">
						<s-select-area
							label="Area"
							v-model="task.AreID"
							ref="tskarearef"
						></s-select-area>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-textarea
							label="Descripción"
							v-model="task.TskDescription"
							ref="tskdescriptionref"
						></s-textarea>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-card>
</template>

<script>
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';

	export default {
		props: {
			project: {
				type: Object,
				default(rawsProps) {
					return { message: 'hello' }
				} 
			},
		},

		data() {
			return {
				task : {},
			}
		},

		methods: {
			closeDialogTask()
			{
				this.$emit("closeDialogTask");
			},

			validationSaveTask(task)
			{
				var errors = {
					message : '',
					error : 0,
				};

				if (task.TskTitle == '')
				{
					errors.error = 1;
					errors.message = 'Ingrese titulo';
					this.$refs.tsktitleref.focus();
				}

				if(task.AreID == '' || task.AreID == null)
				{
					errors.error = 1;
					errors.message = 'Ingrese área';
					this.$refs.tskarearef.focus();
				}

				if(task.TskDescription == '')
				{
					errors.error = 1;
					errors.message = 'Ingrese descripción'
					this.$refs.tskdescriptionref.focus();
				}

				return errors;
			},

			saveTask()
			{
				let errors = this.validationSaveTask(this.task);
				if (errors.error > 0)
				{
					this.$fun.alert(errors.message, "warning");
					return;
				}
				console.log('errors', errors);


				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						this.task.TypeStage = 1;
						this.task.PrjID = this.project.PrjID;
						this.task.UsrID = this.$fun.getUserID();
						this.task.PrsID = this.$fun.getUserInfo().PrsID;
						this.task.UsrCreateID = this.$fun.getUserID();
						this.task.UsrUpdateID = this.$fun.getUserID();

						_sTaskService
						.save(this.task, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guardado Correctamente", "success");
								this.$emit("closeDialogTask");
								return;
							}
						})
					}
				})
			}
		},

	}
</script>

<style scoped>

</style>