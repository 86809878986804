<template>
	<div>
		<v-card dense outline>
			<v-card-title>
				<h2>Mis Proyectos</h2>
			</v-card-title>
			<v-container>
				<v-row>
					<v-col
						v-for="item in projects"
						:key="item.PrjID"
					>
						<v-card dense>
							<v-card-title>
								<h2>{{item.PrjName}}</h2>
							</v-card-title>
							<v-card-text>
								<v-btn
									color="primary"
									@click="openProject(item)"
								>
									{{"Abrir"}}
								</v-btn>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sProjectService from "@/services/InformationTechnology/TicProjectService.js"

	export default {
		data() {
			return {
				projects : []
			}
		},

		methods: {
			openProject(item)
			{
				this.$emit("selectedProject", item);
			},

			getProjects()
			{
				_sProjectService
				.list(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.projects = resp.data;
					}
				})
			}
		},

		created () {
			this.getProjects();
		},
	}
</script>
