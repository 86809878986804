<template>
	<div>
		<highcharts :options="chartOptions"></highcharts>
	</div>
</template>

<script>
	import {Chart} from 'highcharts-vue';
	export default {
		
	}
</script>

<style scoped>

</style>