import Service from "../Service";

const resource = "ticproject/";

export default {
    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },
};